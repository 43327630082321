import React from 'react';

const Message = (props) => {
  
  if (props.content == '//image') {
    return (
      <div class={`message ${props.type} image`} >
          <img src={props.url} />
      </div>
    );
  } else {
    return (
      <div class={`message ${props.type}`} dangerouslySetInnerHTML={{__html: props.content}}>
      </div>
    );
  }
  
}

export default Message;