const benResponses = [
    'same, but different! ... But still the same!',
    `i know nothing! i'm from barcelona!`,
    `i used to be an adventurer just like you... but then i took an arrow in the knee`,
    `meh...`,
    `https://media.giphy.com/media/C6JQPEUsZUyVq/giphy.gif`,
    'https://media2.giphy.com/media/1BRXy8cUS1jyw/giphy.gif?cid=790b76111ef9f24a7792e2abb99acf5ccf095d74d89a575e&rid=giphy.gif',
    'https://media0.giphy.com/media/rcOlpTCkM1GAE/giphy.gif?cid=790b761123c594b77ee74ad847e31f620e2184854293069f&rid=giphy.gif',
    'https://media1.giphy.com/media/Jf8tQksLHhpXa/giphy.gif?cid=790b7611a6f46d25f8d26f1945d07e9f6cec4a86c8daec71&rid=giphy.gif',
    'https://media0.giphy.com/media/5DfGL75M9spG0/giphy.gif?cid=790b7611c0829bfa42fa925cc0e6748550647c6cfb732f02&rid=giphy.gif',
    'https://media2.giphy.com/media/Ui6MJzUYp7X7a/giphy.gif?cid=790b761199495255396666b92212f008ead6e15677738e69&rid=giphy.gif',
    'https://media2.giphy.com/media/nXEbFijsWaGQM/giphy.gif?cid=790b76119d4bf61737ed56836757f2eba5be59acfadae867&rid=giphy.gif',
    'computer says no.',
    'did you try turning it off and on again?',
    'https://i.imgflip.com/3h23ua.jpg',
    'https://i.imgflip.com/3h23y1.jpg',
    'https://i.imgflip.com/3h247a.jpg',
    'https://i.imgflip.com/3h258s.jpg',
    'https://i.imgflip.com/3h25di.jpg',
    'Epic shit is epic!',
    'https://media.giphy.com/media/6HWIE0zOONDu8/giphy.gif',
    'https://i.imgflip.com/3h3bdw.jpg',
    'https://i.imgflip.com/3h3bqb.jpg',
    'https://i.imgflip.com/3h3c0b.jpg',
    'https://media2.giphy.com/media/15BuyagtKucHm/giphy.gif?cid=790b76114b37ffa5e52663aa49caa7eedba3179eafb7094a&rid=giphy.gif',
    `It's me! Monsieur Leclerc!`,
    'Not my problem anymore!',
    `Potato, potato!`,
    `Slash care`,
    `Oh! you dropped something!`,
    `But, wait! there is more!`,
    'https://i.imgur.com/HIyoPiP.jpg'
]

export default benResponses;