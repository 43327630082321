import React, { useState, useRef, useEffect } from 'react';
import Message from './Message';
import benResponses from './benResponses';

const MessageBox = (props) => {

  const handleSubmit = (e) => {
    if (e.key === 'Enter' && e.target.value != '') {
      let r = Math.floor(Math.random() * benResponses.length);
      if (!handleHistory(r)) {
        handleSubmit(e);
        return;
      }
      let updatedMessage = [...messages, {
        type: 'user',
        content: e.target.value
      }]
      setMessages(updatedMessage);
      e.target.value = '';
      let res = benResponses[r];
      if (res.substr(0, 4) == 'http') {
        setMessages([...updatedMessage, {
          type: 'ben',
          content: '//image',
          url: res
        }]);
      } else {
        setMessages([...updatedMessage, {
          type: 'ben',
          content: benResponses[r]
        }]);
      }
    }
  }

  const [messages, setMessages] = useState([
    {
      type: 'ben',
      content: `Greetings wanderer! It's me, <a href='https://en.wiktionary.org/wiki/traitor' target='blank'>Ben</a>, i'm here to share my infinite knowledge! I know you miss me already but you can still feel like i'm here`
    },
    {
      type: 'ben',
      content: `I'm just here to share memes, I will not really answer, chatbot style! 😂😂`
    }
  ])

  const [history, setHistory] = useState([]);

  const handleHistory = (r) => {
    if (history.indexOf(r) !== -1) {
      return false
    } else {
      let newHistory = history;
      if (history.length > 15) {
        newHistory.shift();
      }
      setHistory([...newHistory, r]);
      return true;
    }
  }

  const fakeBottom = useRef();
  useEffect(() => {
    setTimeout(()=> {
      fakeBottom.current.scrollIntoView({behavior: "smooth"});
    },200)
  })

  const content = messages.map((item) => {
    return <Message {...item} class='messageBox' />
  })

  return (
    <div className="messageBox">
      {content}
      <input type='text' onKeyPress={handleSubmit} placeholder='type in your question' ref={props.mainInput} />
      <div class='fakebottom' ref={fakeBottom}></div>
    </div>
  );
}

export default MessageBox;
