import React, { useRef } from 'react';
import './App.css';
import MessageBox from './components/MessageBox';

const App = () => {
  const mainInput = useRef();
  const focusMainInput = () => mainInput.current.focus();
  return (
    <div className="App" onClick={focusMainInput}>
      <header className="App-header">
        <h1>What would <span class="accent">Ben</span> say?</h1>
      </header>
      <MessageBox mainInput={mainInput} />
    </div>
  );
}

export default App;
